import styled from "styled-components"

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 16px;

  &:before,
  &::after {
    content: "";
    height: 1px;
    background-color: silver;
    flex-grow: 1; // both lines will expand to occupy the available space
  }

  ${(props) => {
    if (props.children) {
      return `
      ::before {
        margin-right: 32px;
      }
    
      ::after {
        margin-left: 32px;
      }
      `
    }
  }}
`
