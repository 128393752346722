import { useMutation } from "@apollo/client"
import { termsOfServicePath, privacyPolicyPath, loginPath } from "@app/routes"
import SIGNUP from "@graph/mutations/signup.gql"
import { Signup, SignupVariables } from "@graph/types/Signup"
import { Alert } from "@ui/alert"
import Button from "@ui/button"
import { Card } from "@ui/card"
import { CheckboxField } from "@ui/checkbox-field"
import { Fieldset } from "@ui/fieldset"
import { InputField } from "@ui/input"
import { trackSignup } from "analytics"
import Link from "next/link"
import { useCurrentUser } from "react-current-user"
import { Form, Field } from "@graph/form"
import { required, matches } from "validation"
import { PostSignupOverlay } from "@overlay/post-signup"
import { useState } from "react"

export type SignupCardProps = {
  autoFocus?: boolean
  prefill?: {
    firstName?: string
    lastName?: string
    email?: string
    phoneNumber?: string
    referralCode?: string
  }
}

export const SignupCard = ({ autoFocus, prefill = {} }: SignupCardProps) => {
  const [signup] = useSignup()
  const [, setCurrentUser] = useCurrentUser()

  const [showOverlay, setShowOverlay] = useState(false)

  return (
    <Form
      initialValues={prefill}
      onSubmit={async ({
        firstName,
        lastName,
        email,
        phoneNumber = "",
        password,
        passwordConfirmation,
        referralCode,
      }) => {
        if (!email || !password) return

        const { data, errors } = await signup({
          variables: {
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
            passwordConfirmation,
            referralCode,
          },
        })

        if (!data) {
          return { allFields: "Something went wrong. Please try again or contact support." }
        }

        const user = data.signup?.user
        const submitErrors = data.signup?.errors

        if (errors) {
          return { allFields: "Something went wrong. Please try again." }
        } else if (user) {
          setCurrentUser(user)
          trackSignup()
          setShowOverlay(true)
        } else {
          return submitErrors
        }
      }}
    >
      {({ handleSubmit, values, submitting, submitError, submitFailed, submitSucceeded }) => (
        <Card id="signup-card" heading="Sign up for HeartPayroll" as="form" onSubmit={handleSubmit} variant="splash">
          {showOverlay && <PostSignupOverlay email={values.email as string} />}
          <Fieldset>
            <Field
              id="firstName"
              name="firstName"
              label="First name"
              required
              autoFocus={autoFocus}
              component={InputField}
              validate={required}
            />
            <Field
              id="lastName"
              name="lastName"
              label="Last name"
              required
              component={InputField}
              validate={required}
            />
            <Field id="email" name="email" label="Email" required component={InputField} validate={required} />
            {prefill.phoneNumber && (
              <Field id="phoneNumber" name="phoneNumber" label="Phone number" component={InputField} />
            )}
            <Field
              id="password"
              name="password"
              type="password"
              label="Password"
              required
              component={InputField}
              validate={required}
            />
            <Field
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              label="Confirm password"
              required
              component={InputField}
              validate={matches("password")}
            />
            {prefill.referralCode && (
              <Field id="referralCode" name="referralCode" label="Referral code" disabled component={InputField} />
            )}
            <Field
              id="acceptTermsAndConditions"
              name="acceptTermsAndConditions"
              type="checkbox"
              component={CheckboxField}
              label={
                <>
                  I have read and agree to the{" "}
                  <Link href={termsOfServicePath} passHref>
                    <a>Terms of Service</a>
                  </Link>{" "}
                  and the{" "}
                  <Link href={privacyPolicyPath}>
                    {/* eslint-disable-next-line sentence-case/sentence-case */}
                    <a>Privacy Policy</a>
                  </Link>
                  .
                </>
              }
            />
          </Fieldset>

          <Button
            id="submit-sign-up"
            fluid={[true, false]}
            loading={submitting}
            disabled={submitting || !values.acceptTermsAndConditions}
          >
            Sign up
          </Button>
          {submitFailed && (
            <Alert title="Sign up failed">{submitError || "Please correct the above information and try again."}</Alert>
          )}
          {submitSucceeded && (
            <Alert title="Sign up complete" variant="success">
              Your account has been created. Please check your email to confirm your address.
            </Alert>
          )}
          <div>
            Already have an account?{" "}
            <Link href={loginPath} passHref>
              <a>Login</a>
            </Link>
          </div>
        </Card>
      )}
    </Form>
  )
}

const useSignup = () => {
  return useMutation<Signup, SignupVariables>(SIGNUP)
}
