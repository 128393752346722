import styled from "styled-components"
import { Stack, StackProps } from "@ui/stack"
import { variant, flexBasis, FlexBasisProps, display, DisplayProps } from "styled-system"

export type ColumnProps = DisplayProps &
  StackProps &
  FlexBasisProps & {
    variant?: "primary" | "secondary"
  }

export const Column = styled(Stack)<ColumnProps>`
  width: 100%;
  ${variant({
    variants: {
      primary: { flexBasis: "60%" },
      secondary: { flexBasis: "40%" },
    },
  })}
  ${flexBasis}
  ${display}
`

Column.defaultProps = {
  gap: 5,
}
