import Alert from "@reach/alert"
import css from "@styled-system/css"
import styled from "styled-components"
import { PageBlock } from "@ui/page-block"
import Stack from "@ui/stack"
import { Heading } from "@ui/heading"
import { Headline } from "@ui/headline"
import logo from "../../public/images/heart.jpeg"
import Image from "next/image"
import { Divider } from "@ui/divider"
import Link from "next/link"
import { userConfirmationPath } from "@app/routes"

type PostSignupOverlayProps = {
  email: string
}

export const PostSignupOverlay = ({ email }: PostSignupOverlayProps) => {
  return (
    <Overlay>
      <PageBlock>
        <Stack direction={["vertical", "horizontal"]} alignItems={["center", "flex-start"]}>
          <Image priority alt="" src={logo.src} height={logo.height / 2} width={logo.width / 2} />

          <Stack gap="4">
            <Heading as="h2" level={[2, "m"]} variant="marketing">
              Verify your email address to get started with HeartPayroll
            </Heading>

            <Headline variant="marketing">
              We sent a verification email to <strong>{email}</strong>. Once you verify your email address we'll help
              you setup payroll.
            </Headline>

            <Divider />

            <p>
              Don't see the email? Check your spam folder or{" "}
              <Link href={userConfirmationPath} passHref>
                <a>request a new verification email.</a>
              </Link>
            </p>
          </Stack>
        </Stack>
      </PageBlock>
    </Overlay>
  )
}

const Overlay = styled(Alert)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  height: -webkit-stretch;
  overflow: scroll;

  background: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  ${css({ bg: "primary.10", paddingTop: [0, 6], paddingBottom: [4, 6], alignItems: ["flex-start", "center"] })}
`
