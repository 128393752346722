import styled from "styled-components"
import { Stack } from "@ui/stack"

export const Row = styled(Stack)``

Row.displayName = "Row"

Row.defaultProps = {
  gap: 5,
  direction: ["vertical", "horizontal"],
  justifyContent: "space-between",
}
